.item {
  display: flex;
  justify-content: space-around;

  font-size: 20px;
  margin-top: 15px;
}

.item_label {
  width: 410px;
}

.container {
  width: 550px;
  display: flex;
  justify-content: space-between;
}

.item input {
  width: 457px;
  height: 40px;
  background-color: #161411;
  border: none;
  color: white;
  font-size: 17px;
  text-align: center;
  display: block;
}

.button {
  display: block;
  border: none;
  width: 40px;
  height: 40px;
  margin: 0 3px 0 3px;
  color: white;
  font-size: 22px;
  background-color: #161411;
}