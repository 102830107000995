.button {
  border: 2px solid #E5E5E5;
  background: transparent;
  color: white;
  

  text-transform: uppercase;
  cursor: pointer;
}

.medium {
  width: 250px;
  height: 50px;
  font-size: 24px;
}

.big {
  width: 540px;
  height: 64px;
  font-size: 28px;
}