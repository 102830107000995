.big {
  margin-top: 20px;
  height: 610px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  position: relative;
}
.big_picture {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url('./res/big.png');
}

.row {
  padding: 15px;
  z-index: 10;
  width: 350px;
  background: linear-gradient(90deg, #0F0F0F 13.33%, rgba(15, 15, 15, 0) 100%);
}

.big_first {
  color: #D0A977;
  font-size: 38px;
  margin-top: 150px;
}
.big_second {
  margin-top: 40px;
  font-size: 20px;
}

.big_button {
  margin-top: 30px;
}

.one {
  position: absolute;
  width: 100%;
  height: 70px;
  background: linear-gradient(180deg, #0C0C0C 0%, rgba(12, 12, 12, 0) 100%);
}

.categories {
  margin: 50px 0 20px;
  display: flex;
  flex-direction: column;
}

.categories_title {
  color: #D0A977;
  text-align: center;
  font-size: 28px;
  margin-bottom: 30px;
}

.categories_button {
  text-align: center;
  margin-top: 30px;
}

.categories_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.categories_list_item {
  margin: 15px;
  width: 520px;
  height: 300px;
  position: relative;

  display: flex;
  flex-direction: column-reverse;
}

.categories_list_item div {
  height: 100px;
  font-size: 32px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 142.06%);
  padding-left: 40px;
  padding-top: 30px;
}

.salat {
  background-image: url('./res/salat.png');
}
.sup {
  background-image: url('./res/sup.png');
}
.bluda {
  background-image: url('./res/bluda.png');
}
.garn {
  background-image: url('./res/garn.png');
}