.header_container {
  background-color: #0C0C0C;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 1100px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.header {
  min-width: 1100px;
  width: 1100px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
}

.navigation {
  width: 350px;
  display: flex;
  justify-content: space-around;
  font-size: 18px;
  align-items: center;
}

.link {
  color:white;
  text-decoration: none;
}

.logo {
  font-family: Edwardian;
  font-size: 36px;
  color: #D0A977;
}