.footer_container {
  background-color: #E5E5E5;
  min-width: 1100px;
  height: 80px;
}

.footer {
  min-width: 1100px;
  width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  font-size: 20px;
}

.content {
  display: flex;
  justify-content: space-around;
}

.text {
  font-size: 20px;
}

.button {
  width: 32px;
  height: 32px;
  border: none;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  cursor: pointer;
  outline: none;
}

.button_vk {
  background-image: url('./res/vk.svg');
  background-size: 30px;
}

.button_instagram {
  background-image: url('./res/instagram.svg');
  background-size: 30px;
}

.button_facebook {
  background-image: url('./res/facebook.svg');
  background-size: 30px;
}