.menu_container {
  margin-top: 30px;
  margin-bottom: 30px;
}

.title {
  color: #D0A977;
  font-size: 26px;
  margin-bottom: 30px;
}

.category {
  font-size: 26px;
}

.category_name {
  text-align: center;
  color: #D0A977;
  margin: 10px 0 10px;
}

.item {
  display: flex;
  justify-content: space-between;
  padding: 9px;
  font-size: 21px;
}

.item_name {
  width: 550px;
  margin-left: 50px;
}

.item_wait_time {
  width: 200px;
  text-align: center;
}

.item_price {
  width: 100px;
  text-align: right;
}

.colorized {
  background-color: #342C22;
}
.colorized:nth-child(even) {
  background-color: #201C17;
}