.main_container {
  background-color: #0C0C0C;
  color: white;
  display: flex;
  flex-direction: column;
  min-width: 1100px;
  flex: auto;
}

.main {
  min-width: 1100px;
  width: 1100px;
  margin-left: auto;
  margin-right: auto;
}
