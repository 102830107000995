.about_container {
  margin-top: 30px;
  margin-bottom: 30px;
}

.text_container {
  font-size: 20px;
  display: flex;
  padding: 10px;
}

.text_title {
  font-size: 24px;
  color: #D0A977;
}

.text_leftside {
  width: 100%;
  margin-right: 10px;
}

.text_rightside {
  width: 540px;
  padding: 15px;
}

.text_rightside img {
  width: 450px;
}

